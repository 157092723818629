/* eslint-disable */
import { createPaginator } from './createPaginator'
import paginatorMiddleware from './paginatorMiddleware'
import { requestPageWatcher } from './sagas'

import {
  getCurrentPageNumber,
  getCurrentPageResults,
  getAllResults,
  getCurrentTotalResultsCount,
  isCurrentPageFetching,
  clearPaginatorPromiseArray,
} from './selectors'

export {
  createPaginator,
  paginatorMiddleware,
  requestPageWatcher,
  getCurrentPageNumber,
  getCurrentPageResults,
  getAllResults,
  getCurrentTotalResultsCount,
  isCurrentPageFetching,
  clearPaginatorPromiseArray,
}
/* eslint-enable */
