import { ColumnComponent } from './index';

const MessageVideoTable = () => ([
  {
    Header: 'Name Surname',
    accessor: 'fullName',
    sortable: true,
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Playback time',
    accessor: 'playbackTime',
    sortable: true,
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'playbacks',
    accessor: 'playbacks',
    sortable: true,
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
  {
    Header: 'Plays to End',
    accessor: 'playsToEnd',
    sortable: true,
    Cell: (row) => (
      <ColumnComponent>
        <span>{row.value}</span>
      </ColumnComponent>
    ),
  },
]);

export default MessageVideoTable;
