import { Container } from 'semantic-ui-react';
// stylelint-disable
import 'semantic-ui-css/semantic.min.css';
// stylelint-enable
import '../../assets/styles/main.scss';

const HomePage = () => (
  <Container>
    <h1>content</h1>
  </Container>
);

export default HomePage;
