import { Container } from 'semantic-ui-react';
import Icon from '../Icon/Icon';

const FooterSuperAdmin = () => (
  <div className="app-footer main">
    <div className="main-nofix nofix-superadmin">
      <Icon name="footer-logo" />
      <div className="main-nofix__meta">
        <Container>
          <span>
            ©
            &nbsp; Copyright 2018 Rispons
          </span>
          <span>Terms of service</span>
        </Container>
      </div>
    </div>
  </div>
);

export default FooterSuperAdmin;
