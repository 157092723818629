export const videosFormats = [
  'mp4',
];

export const imagesFormats = [
  'jpeg',
  'jpg',
  'png',
  'gif',
  'bmp',
  'tiff',
];
